input[type="text"] {
  padding-left: 0
}

.login-input {
  width: 100%;
  height: 100%;
  border: none;
  -webkit-appearance: none;
  font-size: 14px;
  letter-spacing: 1.4px;
  font-weight: normal;
  font-style: normal;
  padding: 0;
}

.login-input:focus {
  outline: none;
}
