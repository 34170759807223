@import "src/styles/abstracts/colors";

.switch {
  position: relative;
  display: inline-block;

  .switch-input {
    display: none;
    cursor: pointer;
  }

  .switch-label {
    display: block;
    width: 48px;
    height: 24px;
    user-select: none;
    position: relative;
    cursor: pointer;

    span {
      width: 100px;
      position: absolute;
      top: 0;
      left: 47px;
      cursor: pointer;
      color: #2a2a2a;
    }
  }

  .switch-label::before,
  .switch-label::after {
    content: "";
    display: block;
    position: absolute;
    cursor: pointer;
  }

  .switch-label::before {
    width: 42px;
    height: 18px;
    border: 1px solid #2a2a2a;
    border-radius: 9999em;
    transition: background-color 0.15s ease;
  }

  .switch-label::after {
    top: 3px;
    left: 3px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $text-color-default;
    -webkit-transition: left 0.25s ease;
    transition: left 0.15s ease;
  }

  .switch-input:checked + .switch-label::after {
    left: 27px;
    background-color: $text-color-default;
  }
}

.switch-disabled {
  opacity: 0.7;
}
