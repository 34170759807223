@import "src/styles/abstracts/colors";

.card {
  box-sizing: border-box;
  padding: 7px 15px 0px 15px;
  //box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  box-shadow: 0 0 6px rgba(0, 0, 0, .1607843137254902) !important;
  overflow-y: auto;
  border-radius: 5px;

  &__title {
    font-weight: bold;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;

    span {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &__content {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__white {
    background-color: $white;
  }

}

.row-border-bottom {
  border-bottom: 1px solid #D7D8D9;
}

.card__subtitles {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding-right: 10px;

  div {
    color: #4b4c4d;
    font-size: 12px;

    .water-data-value {
      font-weight: bold;
    }
  }
}
