@import "src/styles/abstracts/colors";

.blue-rounded-button {
  border: 1px solid $header-black;
  font-size: 16px;
  font-weight: 600;
  color: white;
  padding: 0 15px;
  height: 100%;
  border-radius: 3em;
  background: $button-color;
  cursor: pointer;
  text-transform: uppercase;
}

.blue-rounded-button:disabled {
  color: white;
  opacity: 0.3;
  cursor: default;
}

.farms-button-wrapper {
  justify-content: center;
  align-items: center;

  button {
    min-width: 130px;
    height: 44px;
    font-size: 14px;
  }
}

.button-height {
  height: 40px;
}

.add-button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: $main-blue;
  font-size: 16px;

  span {
    margin-right: 5px;
  }
}

.days-counter {
  margin-left: 6px;
  height: 25px;
  font-size: 10px;
  background-color: #fff;
  border-radius: 3em;
  border: 1px solid $button-color;
  cursor: pointer;
  color: $button-color;
  padding: 5px 10px;

  &.active {
    color: #fff;
    background-color: $button-color;
  }

  .hidden {
    visibility: hidden;
  }
}
