@import "src/styles/abstracts/colors";

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltip-text {
  visibility: hidden;
  background-color: white;
  padding: 5px;
  min-width: 200px;
  width: auto;
  border-radius: 2px;
  box-shadow: 0 0 6px rgb(0 0 0 / 16%);
  position: absolute;
  overflow: scroll;
  right: -248px;
  top: 0;
  z-index: 1;

  div {
    margin: 5px 0;

    span {
      color: rgba(0, 0, 0, 0.5);
      font-size: 21px;
      margin-right: 10px;
    }
  }
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.tooltip-text:hover .tooltip-text {
  visibility: visible;
}
