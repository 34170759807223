$dark-blue-title-color: #005587;
$gray-subtitle-color: #6d6d6d;
$main-blue: #185a94;
$header-black: #2a2a2a;
$header-gray: #f1f1f1;
$main-color: #242930;
$button-color: #242930;
$background-header-color: #242930;
$info-panel-gray: $header-gray;
$info-panel-text: #6d6d6d;
$header-bottom-line: #e3c02e;
$border-color: #b2bbbf;
$background-color: #fbfbfb;
$header-text-color: #FFFFFF;
$card-top-background-color: $header-gray;
$card-top-text-color: #242930;
$sensor-card-color: #fff;
$ok-green: #00b672;
$error-red: #f44336;
$orange: #F28E02;
$white: #fff;
$black: #000;
$switch-off: #00436f;
$text-color: #2b3b4a;
$text-color-default: #2a2a2a;
$text-color-disabled: #a0a0a0;
$surface-light: #cfcfcf;
$table-odd-row: #ffffff;
$table-even-row: #fbfbfb;
$table-header-border-bottom: #a0871b;
$gold-accent: #eba51d;
//
$color-popup-background: #01646e;
$color-darken: rgba(0, 0, 0, .2);
$color-border-gray: rgba(0, 0, 0, .54);
$color-search-border: #e6e6e6;
$color-button-active: #fff;
$color-blue-disabled: #abd7ee;

$disabled: #c2c2c2;
$inactive: #dbdbdc;
$active-switch-dot: $main-blue;
