.custom-select {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #888;
  width: 100%;

  &.custom-select__disabled::after {
      display: none;
  }

  select {
    display: block;
    width: 100%;
    min-height: 30px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.54);
    outline: none;
    padding: 0 20px 0 0;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
  }
}


.custom-select::after {
  content: '';
  border-width: 5px;
  border-style: solid;
  border-color: transparent;
  border-top-color: rgba(0, 0, 0, 0.54);
  display: inline-block;
  border-radius: 2px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.custom-select .selector-options {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  list-style: none;
  padding: 5px 0;
  margin: 0;
  background: #ffffff;
  border-radius: 4px;
  z-index: 1;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  max-height: 300px;
  overflow: auto;
}

.custom-select .selector-options li {
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  transition: background 0.3s ease;
  color: black;
}

.custom-select .selector-options li:hover {
  background: rgba(0, 0, 0, 0.1);
}
