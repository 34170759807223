@import "abstracts/colors";

// supported locales
$supportedLocales: en-us, en-gb, bg-bg, cs-cz, da-dk, de-de, el-gr, es-mx, es-ar, fi-fi, fr-fr, hu-hu, it-it, ja-jp, ko-kr, lt-lt, lv-lv, nb-no, nl-nl, pl-pl, pt-br, pt-pt, ro-ro, ru-ru, sk-sk, sl-si, sv-se, tr-tr, zh-chs, zh-cht, th-th, et-ee, vi-vn, id-id;

// actual fonts (see Bug 41392: SenseHub - Wrong Font in non-latin languages (Greek and Russian))
$font-opensans-light: OpenSans-Light;
$font-opensans-semi-bold: OpenSans-SemiBold;
$font-opensans-regular: OpenSans-Regular;
$font-opensans-bold: OpenSans-Bold;

//sizes
$size-header-height: 66px;
$size-header-dropdown: 60px;
$size-footer-height: 48px;
$size-nav-menu-width-collapsed: 83px;
$size-nav-menu-width-expanded: 218px;
$default-font-size: 16px;

// @media screens width
$small-device-max-width: 1300px;

//extend
//@extend-elements
//original selectors
//html, body

body {
  background-color: $background-color;
}

%extend_default {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: $background-color;
}

.frame-corner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  height: 35px !important;
  bottom: -35px !important;
}

.frame-corner::before {
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  right: 0;
  height: 0;
  border-top: 35px solid rgba(0,0,0,0.2);
  border-left: 35px solid transparent;
}

.frame-corner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  border-top: 30px solid #fff;
  border-left: 30px solid transparent;
}

.shadow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 3px solid rgba(0,0,0,0.2);
}

.shadow::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  border-top: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: 6px solid rgba(0,0,0,0.2);
  border-right: 6px solid rgba(0,0,0,0.2);
}

.shadow-green {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 3px solid $color-popup-background;
}

.shadow-green::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  border-top: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: 6px solid $color-popup-background;
  border-right: 6px solid $color-popup-background;
}

.icon, [class*='icon-'] {
  height: 1em;
  width: 1em;
  font-size: 1.5em;
  font-style: normal;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  height: 1em;
  width: 1em;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin left-corner($input-color) {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border-bottom: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid $input-color;
  border-left: 6px solid $input-color;
}

@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin ellipsis() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin popupBorder() {
  border-width: 2px 4px 2px 1px;
  border-style: solid;
  border-color: $color-darken;
  border-top-color: $color-search-border;
  border-left-color: $color-search-border;
}

@mixin popupBorderFoldUp() {
  border-top-color: $color-darken;
  border-bottom-color: $color-search-border;
}

@mixin icon($icon-path, $width, $height){
  background-image: url($icon-path);
  background-size: contain;
  background-repeat: no-repeat;
  width: $width;
  height: $height;
  flex-shrink: 0;
}

@mixin backgroundImage($image-path, $width, $height) {
  background-image: url($image-path);
  background-size: cover;
  background-repeat: no-repeat;
  width: $width;
  height: $height;
}

@mixin button-shadow() {
  -webkit-box-shadow: 3px 3px 5px 0px rgba(50, 50, 50, 0.3);
  -moz-box-shadow:    3px 3px 5px 0px rgba(50, 50, 50, 0.3);
  box-shadow:         3px 3px 5px 0px rgba(50, 50, 50, 0.3);
}

input {
  font-size: 14px;
  background-color: transparent;
}

input.search {
  background-color: transparent;
  // background-color: $background-color !important;
}

select {
  background-color: $background-color;
}

@mixin corner-button($color: $color-button-active, $color-disabled: $color-blue-disabled) {
  .container-buttons {
    display: flex;
    background: $color;
    cursor: pointer;
    width: 20px;
    z-index: 1;
    .check-button {
      display: flex;
      justify-content: center;
      position: relative;
      left: 10px;
      .check-icon {
        width: 31px;
        margin-top: 12px;
        z-index: 1;
        height: 31px;
        //background-image: url("~src/assets/img/common/editor-apply-icon.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }

      .next-icon {
        width: 20px;
        margin-top: 16px;
        margin-left: 5px;
        z-index: 1;
        height: 20px;
        //background-image: url("~src/assets/img/add-animal-wizard/next-arrow-white.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.disabled {
      background: $color-disabled;
      cursor: default;
    }
  }
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  background: rgba($color, $opacity);
}

@mixin disable-text-selection() {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none !important;
}

.left-card-select {
  background-color: $sensor-card-color;
}

.main-checkbox {
  accent-color: $button-color;
}
