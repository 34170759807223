@import "src/styles/abstracts/colors";
@import "src/styles/common";

.dropdown-button {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  width: 155px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  color: $header-text-color;
  border-bottom: 6px solid $background-header-color;
}

.burger-menu {
  .dropdown-button {
    color: $black;
    border-bottom-width: 0;
    width: 100%;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .dropdown-button-active {
    border-bottom-width: 0;
  }
}


.dropdown-button-active {
  border-bottom: 6px solid $header-bottom-line;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.dropdown {
  display: flex;
  align-items: stretch;
  height: 100%;
  cursor: pointer;
  user-select: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: $size-header-dropdown;
  min-width: 190px;
  background-color: $white;
  box-shadow: 0 0 6px rgba(0, 0, 0, .16);
  font-size: 16px;
  z-index: 1;
}

#change-lang {
  left: -56px;
}

.info-menu {
  top: 40px;
  left: -90px;
}

.profile {
  top: 40px;
  left: -170px;

  a:first-child {
    color: $main-blue;
  }
}

.dropdown-content a {
  color: $black;
  padding: 7px 10px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background: rgba(0, 0, 0, 0.05);
}

.show {
  display: block;
}


.e-multi-select-wrapper .e-chips {
  background-color: #eee;
  font-size: 10px;
  border-radius: 0;
  height: 24px;

  & > .e-chipcontent {
    font-size: 10px;
  }
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background: grey;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
  border-color: #b2bbbf;
}

.e-input-group e-control-wrapper e-ddl e-valid-input e-input-focus {

}
