.popup {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;

  &__body {
    background-color: #FFF;
    min-width: 700px;
    padding: 16px 32px 24px;
    overflow-y: auto;
    position: relative;
    box-sizing: border-box;

    @media screen and (max-height: 540px) {
      height: 90vh;
    }

    &-header {
      padding: 5px 0;
      font-size: 20px;
      font-weight: bold;
      color: #243444;
      border-bottom: 1.5px solid rgba(0, 0, 0, .149);
    }

    &-close {
      position: absolute;
      top: 15px;
      right: 20px;
      color: $main-blue;
      cursor: pointer;
    }

    &-content {
      width: 100%;
      // background-color: #fff;
      margin-top: 15px;
      margin-bottom: 20px;
      padding: 20px 20px 20px 0;
      // box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
      min-height: 300px;
      max-height: 80vh;
      overflow: auto;
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      padding: 0 33%;

      button {
        max-width: 100px;
        height: 40px;
      }
    }
  }
}
