@import "styles/styles";

@font-face {
  font-family: Noto Sans;
  src: url('/assets/fonts/notoSans/NotoSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Noto Sans;
  src: url('/assets/fonts/notoSans/NotoSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Noto Sans;
  src: url('/assets/fonts/notoSans/NotoSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Noto Sans;
  src: url('/assets/fonts/notoSans/NotoSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@media print {
  body {
    visibility: hidden;
    width: 100%;
  }
  header {
    display: none;
  }
  #section-to-print {
    visibility: visible;
    overflow: scroll;
    width: 100%;
  }
}

html, body {
  height: 100%;
  margin: 0;
  color: $text-color;

  * {
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    box-sizing: border-box;

  }
}

b {
  font-weight: bold;
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: $button-color;
}

.display-flex {
  display: flex !important;
}

.hidden {
  visibility: hidden;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.align-center {
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.end {
  justify-content: flex-end;
}

.position-relative {
  position: relative;
}

.none {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.visible {
  display: block !important;
}

.height-100 {
  height: 100% !important;
}

.width-100 {
  width: 100% !important;
}

.width-85 {
  width: 85% !important;
}

.width-75 {
  width: 75% !important;
}


.width-50 {
  width: 50% !important;
}

.width-40 {
  width: 40% !important;
}

.width-30 {
  width: 40% !important;
}

.width-25 {
  width: 25% !important;
}

.width-20 {
  width: 25% !important;
}

.width-15 {
  width: 15% !important;
}

.right-margin-10 {
  margin-right: 10px;
}

.left-margin-10 {
  margin-left: 10px;
}

.left-bottom-10 {
  margin-bottom: 10px;
}

.height-80 {
  height: 80% !important;
}
.width-80 {
  width: 80% !important;
}
//Standard dropdown styles
.menus {
  display: flex;

  @media screen and (max-width: 850px) {
    flex-direction: column;
  }


  &__menu {
    box-sizing: border-box;
    width: 225px;

    @media screen and (max-width: 850px) {
      width: 100%;
    }

    &-label {
      font-size: 14px;
      color: rgb(76, 76, 76);
      font-weight: 200;
      letter-spacing: $header-black;
    }

    &-label {
      font-size: 14px;
      color: rgb(76, 76, 76);
      font-weight: 200;
      letter-spacing: $header-black;
    }

    &-sublabel {
      color: $gray-subtitle-color;
      font-size: 12px;
    }

    &-error {
      color: red;
      font-size: 0.8em;
    }

    &-selector {
      box-sizing: border-box;
      padding: 7px 20px 7px 0;
      width: 100%;
      height: 44px;

      &-label {
        color: gray;
        font-size: 13px;
        margin-bottom: 4px;
      }

      select {
        width: 100%;
        background-color: transparent;
      }

      input {
        width: 100%;
        border: none;
        outline: none;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        border-bottom: 1px solid $border-color;
        height: 31px;

        &::placeholder {
          color: $info-panel-text;
        }
      }
    }
  }
}

.disabled {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.42);
  width: 100%;
  font-size: 14px;
  min-height: 25px;
}

.disabled-checkbox {
  opacity: 0.5;
  cursor: default !important;
}

.active {
  font-weight: bold;
}

.reports-table {
  width: 100%;
  border-spacing: 0;
  margin-top: 14px;
  border: solid 1px #e2e2e2;
  border-top-left-radius: 8px 8px;
  border-top-right-radius: 8px 8px;

  &__name {
    color: $main-blue;
    cursor: pointer;
  }

  th {
    border-top-left-radius: 8px 8px;
    border-top-right-radius: 8px 8px;
    font-weight: bold;
    font-size: 12px;
    padding: 10px 16px;
    background-color: $white;
    border-bottom: solid 2px $table-header-border-bottom;
    text-align: left;
  }

  td {
    text-align: left;
    padding: 12px 16px;
    font-size: 16px;
  }
}

.reports-table tbody tr:nth-child(odd) {
  background-color: $table-odd-row;
}

.reports-table tbody tr:nth-child(even) {
  background-color: $table-even-row;
}

.slick-prev, .slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: black !important;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:before, .slick-next:before {
  color: black !important;
}

.zones-menu {
  display: flex;
  justify-content: space-between;

  &__button {
    width: 80px;
    height: 40px;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 420px;
  }
}

.zones-titles {
  margin: 30px 0 30px 0;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    font-size: 20px;
    font-weight: bold;
    color: rgb(76, 76, 76);
  }

  &__subtitle {
    font-size: 16px;
    font-weight: bold;
    color: rgb(76, 76, 76);
  }

  &__dimensions {
    font-size: 14px;
    color: rgb(76, 76, 76);
  }
}

.zones-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.new-farm-button {
  height: 35px;
  margin: 10px;
}


.tabs-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #ccc;

  .tabs {
    height: 40px;
    margin-top: 25px;

    &__tab {
      padding: 8px 30px;
      cursor: pointer;
    }
  }
}

.switch {
  margin-bottom: -8px;
}

.main-blue-text-color {
  color: $main-blue;
}

a {
  text-decoration: none;
}

.margin-0-5 {
  margin: 0 5px;
}

.margin-top-15 {
  margin-top: 15px;
}

.ok {
  color: #00b672;
}

.warn {
  color: orange;
}

.error {
  color: red;
}

table {
  th.sortable {
    cursor: pointer;
    position: relative;

    .arrow {
      position: absolute;
      font-size: 18px;
      top: 11px;
    }
  }
}

.user-select-none {
  user-select: none;
}

.crop-is-closed {
  font-weight: bold;
  color: red;
}

.add-flock {
  margin-top: 10px;
  cursor: pointer;
  width: 370px;

  span {
    font-size: 24px;
    color: $main-blue;
  }
}

.margin0 {
  margin: 0 !important;
}

.flock-remover {
  min-width: 24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: -8px;
  top: 15px;

  div {
    margin-top: 15px;
    display: flex;
    align-items: center;
    column-gap: 1em;
  }
}

.sensor-graph-legend {
  position: relative;

  .target-sensor-value,
  .actual-sensor-value {
    display: inline-block;
  }

  .actual-sensor-value {
    margin-left: 10px;
  }

  .target-sensor-legend {
    display: inline-block;
    position: relative;
    top: 1px;
    margin-left: 25px;
    margin-right: 10px;
    border-width: 6px;
    border-style: solid;
  }

  .actual-sensor-legend {
    display: inline-block;
    position: relative;
    margin-left: 25px;
    top: 1px;
    border-width: 6px;
    border-style: solid;
  }
}

copyright {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.position-relative {
  position: relative;
}


.house__wrapper {
  display: flex;
  box-shadow: rgb(0 0 0 / 16%) 0 0 10px;
  margin: 15px;
  background: $white;
}

.info__wrapper {
  width: 450px;
  font-size: 16px;
  padding: 35px 29px 26px;
  background-color: $info-panel-gray;
  color: $info-panel-text;
}

.info {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  &__body {

    &-header {
      margin-bottom: 39px;
      font-size: 18px;
      font-weight: bold;
      color: $header-black;
    }

    &-content {
      display: flex;
      flex-wrap: wrap;

      .width-25 span {
        white-space: nowrap;
        color: $header-black;
        font-weight: bold;
        margin-bottom: 17px;
        display: inline-block;
      }
    }
  }
}

.daily-water {
  margin-bottom: 10px;
}

.language-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
}

.text-align-center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.text-align-center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.characters-left {
  color: #808080;
  font-style: italic;
  display: block;
  margin-top: -3px;
}

.e-active {
  color: $main-blue !important;
}

.e-multi-select-wrapper input {
  border-bottom: 1px solid $border-color;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}

.svg {
  display: inline-block;
  mask-size: cover;
}

.close-svg-blue {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  mask: url('assets/img/common/Close.svg') no-repeat center;
}

.close-svg-white {
  width: 24px;
  height: 24px;
  background-color: $white;
  mask: url('assets/img/common/Close.svg') no-repeat center;
}

.expand-more-white-small {
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);
  background-color: $white;
  mask: url('assets/img/common/back-small.svg') no-repeat center;
}

.expand-more-small {
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);
  background-color: $text-color;
  mask: url('assets/img/common/back-small.svg') no-repeat center;
}

.expand-less-small {
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
  background-color: $text-color;
  mask: url('assets/img/common/back-small.svg') no-repeat center;
}

.error-red-small {
  width: 16px;
  height: 16px;
  background-color: $error-red;
  mask: url('assets/img/common/error-small.svg') no-repeat center;
}

.notification-white {
  width: 24px;
  height: 24px;
  background-color: $white;
  mask: url('assets/img/common/Notification.svg') no-repeat center;
}

.warning-orange-small {
  width: 12px;
  height: 12px;
  background-color: $orange;
  mask: url('assets/img/common/Warning-small.svg') no-repeat center;
}

.warning-orange {
  width: 24px;
  height: 24px;
  background-color: $orange;
  mask: url('assets/img/common/Warning.svg') no-repeat center;
}

.back {
  width: 24px;
  height: 24px;
  background-color: $text-color;
  mask: url('assets/img/common/back.svg') no-repeat center;
}

.back-blue {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  mask: url('assets/img/common/back-small.svg') no-repeat center;
}

.skip-to-end {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  mask: url('assets/img/common/skip_next.svg') no-repeat center;
}

.skip-to-start {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  transform: rotate(180deg);
  mask: url('assets/img/common/skip_next.svg') no-repeat center;
}

.next-blue {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  transform: rotate(180deg);
  mask: url('assets/img/common/back-small.svg') no-repeat center;
}

.burger-menu-svg {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  mask: url('assets/img/common/burger-menu.svg') no-repeat center;
}

.play-blue {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  mask: url('assets/img/common/Play.svg') no-repeat center;
}

.pause-blue {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  mask: url('assets/img/common/pause.svg') no-repeat center;
}

.info-svg-blue {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  mask: url('assets/img/common/Info.svg') no-repeat center;
}

.info-svg-blue-small {
  width: 16px;
  height: 16px;
  background-color: $main-blue;
  mask: url('assets/img/common/Info-small.svg') no-repeat center;
}

.delete-blue {
  width: 20px;
  height: 20px;
  background-color: $main-blue;
  mask: url('assets/img/common/delete.svg') no-repeat center;
}

.data-not-updated-filled {
  width: 20px;
  height: 20px;
  background-color: $main-blue;
  mask: url('assets/img/common/data_not_updated_filled.svg') no-repeat center;
}

.delete-inactive {
  width: 20px;
  height: 20px;
  background-color: $inactive;
  mask: url('assets/img/common/delete.svg') no-repeat center;
}

.edit-blue {
  width: 20px;
  height: 20px;
  background-color: $main-blue;
  mask: url('assets/img/common/edit.svg') no-repeat center;
}

.edit-white {
  width: 20px;
  height: 20px;
  background-color: $white;
  mask: url('assets/img/common/edit.svg') no-repeat center;
}

.edit-blue {
  width: 20px;
  height: 20px;
  background-color: $main-blue;
  mask: url('assets/img/common/edit.svg') no-repeat center;
}

.house {
  width: 32px;
  height: 32px;
  background-color: $black;
  opacity: 0.5;
  mask: url('assets/img/farmsOverview/house.svg') no-repeat center;
}


.add-full-filled {
  width: 24px;
  height: 24px;
  background-color: $black;
  mask: url('assets/img/common/add_full_filled.svg') no-repeat center;
}
.refresh-outlined {
  width: 24px;
  height: 24px;
  background-color: $black;
  mask: url('assets/img/common/refresh_outlined.svg') no-repeat center;
}
.refresh-outlined-white {
  width: 24px;
  height: 24px;
  background-color: white;
  mask: url('assets/img/common/refresh_outlined.svg') no-repeat center;
}

.edit-inactive {
  width: 20px;
  height: 20px;
  background-color: $inactive;
  mask: url('assets/img/common/edit.svg') no-repeat center;
}

.complete-blue {
  width: 20px;
  height: 20px;
  background-color: $main-blue;
  mask: url('assets/img/common/checkbox-selected.svg') no-repeat center;
}

.complete-green {
  width: 20px;
  height: 20px;
  background-color: $ok-green;
  mask: url('assets/img/common/checkbox-selected.svg') no-repeat center;
}

.complete-inactive {
  width: 20px;
  height: 20px;
  background-color: $main-blue;
  mask: url('assets/img/common/checkbox-selected.svg') no-repeat center;
}

.complete-disabled {
  width: 20px;
  height: 20px;
  background-color: $inactive;
  mask: url('assets/img/common/checkbox-selected.svg') no-repeat center;
}

.call {
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  mask: url('assets/img/common/Phone.svg') no-repeat center;
}

.location {
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  mask: url('assets/img/common/location_on.svg') no-repeat center;
}

.location-green {
  width: 20px;
  height: 20px;
  background-color: $ok-green;
  mask: url('assets/img/common/location_on.svg') no-repeat center;
}

.location-orange {
  width: 20px;
  height: 20px;
  background-color: $orange;
  mask: url('assets/img/common/location_on.svg') no-repeat center;
}

.location-blue {
  width: 20px;
  height: 20px;
  background-color: $main-blue;
  mask: url('assets/img/common/location_on.svg') no-repeat center;
}

.email-svg {
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  mask: url('assets/img/common/mail.svg') no-repeat center;
}

.apartment {
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  mask: url('assets/img/common/apartment.svg') no-repeat center;
}

.article {
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  mask: url('assets/img/common/article.svg') no-repeat center;
}

.article-blue {
  width: 20px;
  height: 20px;
  background-color: $main-blue;
  mask: url('assets/img/common/article.svg') no-repeat center;
}

.case {
  width: 20px;
  height: 20px;
  background-color: $main-blue;
  mask: url('assets/img/common/Customer.svg') no-repeat center;
}

.users {
  width: 20px;
  height: 20px;
  background-color: $main-blue;
  mask: url('assets/img/common/Users.svg') no-repeat center;
}

.houses-svg {
  width: 20px;
  height: 20px;
  background-color: $main-blue;
  mask: url('assets/img/common/Houses.svg') no-repeat center;
}

.disable-svg-red-small {
  width: 12px;
  height: 12px;
  background-color: $error-red;
  transform: rotate(45deg);
  mask: url('assets/img/common/add-full-small.svg') no-repeat center;
}

.disable-svg-red {
  width: 24px;
  height: 24px;
  background-color: $error-red;
  transform: rotate(45deg);
  mask: url('assets/img/common/add-full.svg') no-repeat center;
}

.done-green-small {
  width: 12px;
  height: 12px;
  background-color: $ok-green;
  mask: url('assets/img/common/Done-small.svg') no-repeat center;
}

.done-green {
  width: 24px;
  height: 24px;
  background-color: $ok-green;
  mask: url('assets/img/common/Done.svg') no-repeat center;
}

.scale-svg-green {
  width: 24px;
  height: 24px;
  background-color: $ok-green;
  mask: url('assets/img/common/Weight.svg') no-repeat center;
}

.scale-svg-yellow {
  width: 24px;
  height: 24px;
  background-color: $gold-accent;
  mask: url('assets/img/common/Weight.svg') no-repeat center;
}

.scale-svg-red {
  width: 24px;
  height: 24px;
  background-color: $error-red;
  mask: url('assets/img/common/Weight.svg') no-repeat center;
}

.chart-svg-blue {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  mask: url('assets/img/common/Graph.svg') no-repeat center;
}

.repeat_icon-blue {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  mask: url('assets/img/common/repeat_icon.svg') no-repeat center;
}

.history-blue {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  mask: url('assets/img/common/history.svg') no-repeat center;
}

.reset-password-blue {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  mask: url('assets/img/common/Reset Password.svg') no-repeat center;
}

.reset-blue {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  mask: url('assets/img/common/reset.svg') no-repeat center;
}

.delete-blue {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  mask: url('assets/img/common/delete.svg') no-repeat center;
}

.duplicate-blue {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  mask: url('assets/img/common/duplicate.svg') no-repeat center;
}

.add-blue {
  width: 24px;
  height: 24px;
  background-color: $main-blue;
  mask: url('assets/img/common/add-full.svg') no-repeat center;
}

.arrow-upward-svg {
  width: 14px;
  height: 14px;
  background-color: $text-color;
  mask: url('assets/img/common/sort-ascending.svg') no-repeat center;
}
.arrow-downward-svg {
  width: 14px;
  height: 14px;
  background-color: $text-color;
  mask: url('assets/img/common/sort-descending.svg') no-repeat center;
}

.add-from-gallery-svg {
  width: 40px;
  height: 40px;
  background-color: $text-color;
  mask: url('assets/img/common/Add from Gallery.svg') no-repeat center;
}

.add-photo-svg {
  width: 40px;
  height: 40px;
  background-color: $text-color;
  mask: url('assets/img/common/Add photo.svg') no-repeat center;
}

.add_manually {
  width: 40px;
  height: 40px;
  background-color: $text-color;
  mask: url('assets/img/common/add_manually.svg') no-repeat center;
}

.pendo-discover-svg {
  width: 20px;
  height: 20px;
  background-color: white;
  mask: url('assets/img/pendo/discover.svg') no-repeat center;
}

.wind-speed-svg {
  width: 20px;
  height: 20px;
  background-color: $main-blue;
  mask: url('assets/img/dashboard/Fan.svg') no-repeat center;
}

.capital-case {
  text-transform: capitalize;
}

textarea:focus, input:focus{
  outline: none;
}


//Hide border for default "today" date
//.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
//  border-color: transparent !important;
//}
//
//.owl-dt-calendar-table .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today {
//  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.0) !important;
//}
//
//.owl-dt-calendar-table .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
//  background-color: transparent !important;
//}
