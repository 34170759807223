@import "abstracts/colors";
@import "components/buttons";
@import "components/inputs";
@import "components/switch";
@import "components/cards";
@import "components/selects";
@import "components/dropdowns";
@import "components/tooltips";
@import "components/popups";
@import "components/tabs";
@import "components/material-syncfusion";
@import "common";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: $black;
}
